<template>
  <v-container>
    <v-row justify="space-around">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        transition="dialog-top-transition"
      >
        <v-card>
          <v-toolbar color="primary" dark>Estados de Cuenta </v-toolbar>
          <v-progress-linear
            v-if="loading"
            loading="loading"
            indeterminate
            color="teal"
          ></v-progress-linear>
          <div class="text-h2 pa-8">
            <v-form ref="myform" v-model="valid" :lazy_validation="false">
              <v-row>
                <v-col cols="12" md="6">
                  <company-field
                    :textLabel="'Sucursal Origen'"
                    v-model="companyId"
                    @value="(val) => (companyId = val)"
                  ></company-field>
                </v-col>
                <v-col cols="12" md="6">
                  <internal-account-field
                    v-model="accountInternal"
                    :isRequired="true"
                    :sourceCompany="companyId"
                    :reset="resetAccountInternal"
                  ></internal-account-field>
                </v-col>
              </v-row>
            </v-form>
          </div>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="close"> Cerrar </v-btn>
            <v-btn color="blue darken-1" text :disabled="!valid" @click="getPDF()">
              Generar Reporte PDF
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>
<script>
import rules from "@/utils/form.rules";
import requests from "@/services/requests";
import blobResponse from "@/utils/response.blob";
export default {
  components: {
    CompanyField: () => import("@/components/fields/CompanyField.vue"),
    InternalAccountField: () => import("@/components/fields/AccountInternalField.vue"),
  },
  props: {
    dialog: { type: Boolean, required: true },
  },
  data() {
    return {
      companyId: 0,
      accountInternal: {},
      resetAccountInternal: false,
      loading: false,
      valid: false,
      // rules
      required: [rules.required],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    resetForm() {
      this.$refs.myform.reset();
      this.valid = false;
      this.companyId = 0;
      this.accountInternal = {};
    },
    getPDF() {
      this.loading = true;
      this.valid = false;
      if (this.$refs.myform.validate()) {
        var api = "api/pdf/internal-account-status/" + this.accountInternal.id;
        requests.get(api).then((response) => {
          if (response.status == 200) {
            blobResponse(response.data, "application/pdf");
            this.resetForm();
            this.$emit("close");
            this.$toasted.global.info({ message: "El reporte fue generado" });
          } else {
            this.valid = true;
            this.$toasted.global.error();
          }
          this.loading = false;
        });
      }
    },
  },
  watch: {
    companyId(val) {
      if (typeof val === "object") {
        this.companyId = this.companyId.id;
      }
      this.resetAccountInternal = this.resetAccountInternal ? false : true;
    },
  },
};
</script>
